define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-post-event-invitee", ["exports", "@ember/utils", "virtual-dom", "discourse/lib/utilities", "discourse/widgets/post", "discourse/widgets/widget", "I18n"], function (_exports, _utils, _virtualDom, _utilities, _post, _widget, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-post-event-invitee", {
    tagName: "li.event-invitee",
    buildClasses(attrs) {
      const classes = [];
      if ((0, _utils.isPresent)(attrs.invitee.status)) {
        classes.push(`status-${attrs.invitee.status}`);
      }
      if (this.currentUser && this.currentUser.username === attrs.invitee.user.username) {
        classes.push("is-current-user");
      }
      return classes;
    },
    html(attrs) {
      const {
        name,
        username,
        avatar_template
      } = attrs.invitee.user;
      let statusIcon;
      switch (attrs.invitee.status) {
        case "going":
          statusIcon = "fa-check";
          break;
        case "interested":
          statusIcon = "fa-star";
          break;
        case "not_going":
          statusIcon = "fa-times";
          break;
      }
      const avatarContent = [(0, _post.avatarImg)(this.site.mobileView ? "tiny" : "large", {
        template: avatar_template,
        username: name || (0, _utilities.formatUsername)(username)
      })];
      if (statusIcon) {
        avatarContent.push(this.attach("avatar-flair", {
          flair_name: _I18n.default.t(`discourse_post_event.models.invitee.status.${attrs.invitee.status}`),
          flair_url: statusIcon
        }));
      }
      return (0, _virtualDom.h)("a", {
        attributes: {
          class: "topic-invitee-avatar",
          "data-user-card": username
        }
      }, avatarContent);
    }
  });
});