define("discourse/plugins/discourse-calendar/discourse/connectors/header-topic-title-suffix/event-date-container", ["exports", "@glimmer/component", "discourse/plugins/discourse-calendar/discourse/components/event-date", "@ember/template-factory", "@ember/component"], function (_exports, _component, _eventDate, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class EventDateContainer extends _component.default {}
  _exports.default = EventDateContainer;
  _class = EventDateContainer;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <EventDate @topic={{@outletArgs.topic}} />
    
  */
  {
    "id": "nQW9kTsJ",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/connectors/header-topic-title-suffix/event-date-container.js",
    "scope": () => [_eventDate.default],
    "isStrictMode": true
  }), _class);
});