define("discourse/plugins/discourse-calendar/discourse/widgets/more-dropdown", ["exports", "discourse/widgets/widget", "I18n"], function (_exports, _widget, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("more-dropdown", {
    tagName: "div.more-dropdown",
    buildKey: () => "more-dropdown",
    transform(attrs) {
      return {
        content: this._buildContent(attrs),
        onChange: item => this.sendWidgetAction(item.id, item.param),
        options: {}
      };
    },
    template: function (attrs, state) {
      var _r = [];
      _r.push("\n    ");
      _r.push(this.attach("widget-dropdown", {
        "id": "more-dropdown",
        "translatedLabel": "More",
        "icon": "ellipsis-h",
        "content": this.transformed.content,
        "onChange": this.transformed.onChange,
        "options": this.transformed.options
      }, undefined, undefined));
      _r.push("\n  ");
      return _r;
    },
    buildClasses(attrs) {
      const content = this._buildContent(attrs);
      if (!content.length) {
        return ["has-no-actions"];
      }
    },
    _buildContent(attrs) {
      const content = [];
      if (!attrs.eventModel.is_expired) {
        content.push({
          id: "addToCalendar",
          icon: "file",
          label: "discourse_post_event.event_ui.add_to_calendar"
        });
      }
      if (this.currentUser) {
        content.push({
          id: "sendPMToCreator",
          icon: "envelope",
          translatedLabel: _I18n.default.t("discourse_post_event.event_ui.send_pm_to_creator", {
            username: attrs.eventModel.creator.username
          })
        });
      }
      if (!attrs.is_expired && attrs.canActOnEvent && attrs.isPublicEvent) {
        content.push({
          id: "inviteUserOrGroup",
          icon: "user-plus",
          label: "discourse_post_event.event_ui.invite",
          param: attrs.eventModel.id
        });
      }
      if (attrs.eventModel.watching_invitee && attrs.isPublicEvent) {
        content.push({
          id: "leaveEvent",
          icon: "times",
          label: "discourse_post_event.event_ui.leave",
          param: attrs.eventModel.id
        });
      }
      if (attrs.canActOnEvent) {
        content.push("separator");
        content.push({
          icon: "file-csv",
          id: "exportPostEvent",
          label: "discourse_post_event.event_ui.export_event",
          param: attrs.eventModel.id
        });
        if (!attrs.eventModel.is_expired && !attrs.eventModel.is_standalone) {
          content.push({
            icon: "file-upload",
            id: "bulkInvite",
            label: "discourse_post_event.event_ui.bulk_invite",
            param: attrs.eventModel
          });
        }
        content.push({
          icon: "pencil-alt",
          id: "editPostEvent",
          label: "discourse_post_event.event_ui.edit_event",
          param: attrs.eventModel.id
        });
        if (!attrs.eventModel.is_expired) {
          content.push({
            icon: "times",
            id: "closeEvent",
            label: "discourse_post_event.event_ui.close_event",
            class: "danger",
            param: attrs.eventModel
          });
        }
      }
      return content;
    }
  });
});