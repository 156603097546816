define("discourse/plugins/discourse-calendar/discourse/initializers/add-hamburger-menu-action", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeHamburgerMenu(api) {
    api.decorateWidget("hamburger-menu:generalLinks", () => {
      return {
        icon: "calendar-day",
        route: "discourse-post-event-upcoming-events",
        label: "discourse_post_event.upcoming_events.title"
      };
    });
  }
  var _default = _exports.default = {
    name: "add-hamburger-menu-action",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_post_event_enabled && siteSettings.sidebar_show_upcoming_events) {
        (0, _pluginApi.withPluginApi)("0.8.7", initializeHamburgerMenu);
      }
    }
  };
});