define("discourse/plugins/discourse-calendar/discourse/components/upcoming-events-calendar", ["exports", "@ember/component", "@ember/runloop", "rsvp", "discourse/lib/load-script", "discourse-common/lib/get-url", "discourse/plugins/discourse-calendar/discourse/helpers/format-event-name", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format", "@ember/template-factory"], function (_exports, _component, _runloop, _rsvp, _loadScript, _getUrl, _formatEventName, _guessBestDateFormat, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="upcoming-events-calendar"></div>
  */
  {
    "id": "unERWDqE",
    "block": "[[[10,0],[14,1,\"upcoming-events-calendar\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/upcoming-events-calendar.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: "",
    events: null,
    init() {
      this._super(...arguments);
      this._calendar = null;
    },
    willDestroyElement() {
      this._super(...arguments);
      this._calendar && this._calendar.destroy();
      this._calendar = null;
    },
    didInsertElement() {
      this._super(...arguments);
      this._renderCalendar();
    },
    _renderCalendar() {
      const calendarNode = document.getElementById("upcoming-events-calendar");
      if (!calendarNode) {
        return;
      }
      calendarNode.innerHTML = "";
      this._loadCalendar().then(() => {
        this._calendar = new window.FullCalendar.Calendar(calendarNode, {});
        (this.events || []).forEach(event => {
          const {
            starts_at,
            ends_at,
            post,
            category_id
          } = event;
          const categoryColor = this.site.categoriesById[category_id]?.color;
          const backgroundColor = categoryColor ? `#${categoryColor}` : undefined;
          this._calendar.addEvent({
            title: (0, _formatEventName.formatEventName)(event),
            start: starts_at,
            end: ends_at || starts_at,
            allDay: !(0, _guessBestDateFormat.isNotFullDayEvent)(moment(starts_at), moment(ends_at)),
            url: (0, _getUrl.default)(`/t/-/${post.topic.id}/${post.post_number}`),
            backgroundColor
          });
        });
        this._calendar.render();
      });
    },
    _loadCalendar() {
      return new _rsvp.Promise(resolve => {
        (0, _loadScript.default)("/plugins/discourse-calendar/javascripts/fullcalendar-with-moment-timezone.min.js").then(() => {
          (0, _runloop.schedule)("afterRender", () => {
            if (this.isDestroying || this.isDestroyed) {
              return;
            }
            resolve();
          });
        });
      });
    }
  }));
});