define("discourse/plugins/discourse-calendar/discourse/components/toggle-invitees", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="invitees-type-filter">
    <DButton
      @label="discourse_post_event.models.invitee.status.going"
      class={{concat-class
        "btn toggle-going"
        (if (eq @viewType "going") "btn-danger" "btn-default")
      }}
      @action={{fn @toggle "going"}}
    />
  
    <DButton
      @label="discourse_post_event.models.invitee.status.interested"
      class={{concat-class
        "btn toggle-interested"
        (if (eq @viewType "interested") "btn-danger" "btn-default")
      }}
      @action={{fn @toggle "interested"}}
    />
  
    <DButton
      @label="discourse_post_event.models.invitee.status.not_going"
      class={{concat-class
        "btn toggle-not-going"
        (if (eq @viewType "not_going") "btn-danger" "btn-default")
      }}
      @action={{fn @toggle "not_going"}}
    />
  </div>
  */
  {
    "id": "Nzkt4dMF",
    "block": "[[[10,0],[14,0,\"invitees-type-filter\"],[12],[1,\"\\n  \"],[8,[39,0],[[16,0,[28,[37,1],[\"btn toggle-going\",[52,[28,[37,3],[[30,1],\"going\"],null],\"btn-danger\",\"btn-default\"]],null]]],[[\"@label\",\"@action\"],[\"discourse_post_event.models.invitee.status.going\",[28,[37,4],[[30,2],\"going\"],null]]],null],[1,\"\\n\\n  \"],[8,[39,0],[[16,0,[28,[37,1],[\"btn toggle-interested\",[52,[28,[37,3],[[30,1],\"interested\"],null],\"btn-danger\",\"btn-default\"]],null]]],[[\"@label\",\"@action\"],[\"discourse_post_event.models.invitee.status.interested\",[28,[37,4],[[30,2],\"interested\"],null]]],null],[1,\"\\n\\n  \"],[8,[39,0],[[16,0,[28,[37,1],[\"btn toggle-not-going\",[52,[28,[37,3],[[30,1],\"not_going\"],null],\"btn-danger\",\"btn-default\"]],null]]],[[\"@label\",\"@action\"],[\"discourse_post_event.models.invitee.status.not_going\",[28,[37,4],[[30,2],\"not_going\"],null]]],null],[1,\"\\n\"],[13]],[\"@viewType\",\"@toggle\"],false,[\"d-button\",\"concat-class\",\"if\",\"eq\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/toggle-invitees.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});