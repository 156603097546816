define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-post-event-dates", ["exports", "virtual-dom", "discourse/widgets/raw-html", "discourse/widgets/widget", "discourse-common/lib/icon-library", "I18n"], function (_exports, _virtualDom, _rawHtml, _widget, _iconLibrary, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-post-event-dates", {
    tagName: "section.event-dates",
    showAllParticipatingInvitees(postId) {
      this.sendWidgetAction("showAllInvitees", {
        postId,
        title: "title_participated",
        extraClass: "participated"
      });
    },
    html(attrs) {
      const content = [(0, _iconLibrary.iconNode)("clock"), (0, _virtualDom.h)("span.date", new _rawHtml.default({
        html: `<span>${attrs.localDates}</span>`
      }))];
      if (attrs.eventModel.is_expired && attrs.eventModel.status !== "standalone") {
        let participants;
        const label = _I18n.default.t("discourse_post_event.event_ui.participants", {
          count: attrs.eventModel.stats.going
        });
        if (attrs.eventModel.stats.going > 0) {
          participants = this.attach("link", {
            action: "showAllParticipatingInvitees",
            actionParam: attrs.eventModel.id,
            contents: () => label
          });
        } else {
          participants = label;
        }
        content.push((0, _virtualDom.h)("span.participants", [(0, _virtualDom.h)("span", " - "), participants]));
      }
      return content;
    }
  });
});